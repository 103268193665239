.minut-hour-cnt {
  position: absolute;
  width: 80%;
  min-height: 200px;
  top: -2px;
  right: -16px;
  padding: 12px;
  background: #fff;
  border: 1px solid #d0d0d0;
  .m2h-tbl-header {
    align-items: center;
    background: #f3f3f3;
    display: flex;
    margin-top: 20px;
    > div {
      width: 50%;
      min-height: 40px;
      color: #1d1d1d;
      text-align: center;
      font: normal 700 14px "Honeywell-Medium";
      padding: 10px;
      text-transform: uppercase;
    }
  }
  .m2h-tbl-row {
    align-items: center;
    text-align: center;
    display: flex;
    > div {
      width: 50%;
      min-height: 40px;
      font: normal 400 16px "Honeywell-Medium";
      padding: 10px;
      border-bottom: 1px solid #e9e9e9;
    }
    &:last-child > div {
      border-bottom: 0;
    }
  }
}
