.hdr-inr {
  background-color: #fff;
  max-width: 98%;
  margin: 0 auto;
  h1 {
    text-transform: capitalize;
    color: #1d1d1d;
    padding-bottom: 20px;
    font: normal 700 40px "Honeywell-Medium";
  }
  .hdr-sub {
    text-transform: uppercase;
    text-align: left;
    color: #1d1d1d;
    padding-top: 20px;
    font: normal 700 11px "Honeywell-Medium";
    line-height: 14px;
  }
  .report-invoice {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 22px;
    a {
      text-decoration: none;
      margin-right: 21px;
      text-transform: uppercase;
      color: #0071b3;
      font: 14px "Honeywell-Bold";
    }
  }
}
