.re-pdt-cnt.invdetail-cnt {
    box-shadow: -2px 2px 5px 5px #8888884a;
    margin: 0;
    p {
      font: normal 400 12px "Honeywell-Medium";
      margin: 4px 0;
      line-height: 12px;
    }
    strong {
      font: normal 800 11px "Honeywell-Medium" !important;
    }
    .pdf-header {
      display: flex;
      width: 100%;
      color: #000;
      .hw-pdf-logo {
        width: 58%;
        padding: 10px 0 0 20px;
        img {
          width: 160px;
        }
        span {
          font: normal 600 11px "Honeywell-Medium";
          color: #6c757d;
        }
        strong {
          color: #313131;
          font: normal 800 11px "Honeywell-Medium";
        }
      }
  
      .customer-info {
        width: 30%;
        padding: 20px 5px 10px 38px;
        >span{
            display: block;
            margin-top: 7px;
        }
        .customer-name {
          font: normal 800 13px "Honeywell-Medium";
          color: #313131;
        }
        span {
          font: normal 600 10px "Honeywell-Medium";
          color: #1d1d1d;
        }
        .email {
          white-space: nowrap;
        }
      }
    }
  
    .pdf-body {
      color: #1d1d1d;
      .subject {
        font: normal 800 14px "Honeywell-Medium";
        color: #000;
      }
  
      > p {
        font: normal 700 11px "Honeywell-Medium";
        color: #1d1d1d;
        &.subject + p > sup {
          top: -1px;
        }
      }
    }
  }
  .note-cnt{
      >p{
       padding-left: 10px;   
      }
      >div{
        font: normal 500 12px "Honeywell-Medium";
        line-height: 15px;
        margin: 6px 0 0 10px;
      }
  }
  .product-table {
    width: 98%;
    margin: 0 auto 5px;
    tr:nth-child(even) {
      background-color: #f3f3f3;
    }
    th,
    td {
      font: normal 600 12px "Honeywell-book";
      padding: 3px 5px;
      width: 9%;
      color: #1d1d1d;
      text-align: center;
      &:first-child {
        width: 9%;
      }
    }
  
    th {
      color: #000;
      font: normal 600 12px "Honeywell-book";
      text-align: center;
    }
  }
  .btn_invoice {
    width: 450px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  hr {
    color: #000;
    border: 2px solid black;
  }
  div.invoice-body {
    display: flex;
    margin: 4px;
    .customer-info {
      width: 55%;
      padding: 0 1%;
      margin: 0;
      &:last-child {
        width: 43%;
      }
      > span {
        font: normal 500 12px "Honeywell-Medium";
        display: block;    
        margin:3px 1px;   
      }
    }
  }
  .display-header {
    padding: 3px 12px;
    .invoice-deatil-header {
      font: normal 700 12px "Honeywell-Medium";
    }
    .terms-hdr {
      font: normal 700 12px "Honeywell-Medium";
      margin-right: 15px;
      float: right;
    }
  }
  .display-header1 {
    padding: 3px 10px;
    .invoice-deatil-header {
      font: normal 700 12px "Honeywell-book";
    }
  }
  .display-header2 {
    padding: 3px 10px;
    .invoice-deatil-header {
      font: normal 700 12px "Honeywell-book";
    }
  }
  
  .invoice-data .invoice-pdf{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin:12px 16px 15px 0 ;
    > a{
      padding-right: 20px;
      font: normal 500 12px "Honeywell-Medium";
      color: #007bc2 !important;
      white-space: nowrap;
      text-decoration: underline !important;
    }
  }
  .invoice-data .customer-info {
    > span {
      margin-bottom: -4px;
      > span {
        display: inline-block;
        max-width: 58%;
      }
      > strong {
        width: 114px;
        display: inline-block;
      }
    }
  }
  .notes {
    font: normal 600 12px "Honeywell-book";
    padding: 6px;
    display: block;
  }
  
  .tbl-cnt {
    width: 100%;
    overflow-x: auto;
    .final-amount {
      text-align: right;
    }
  }
  tfoot {
    td {
      text-align: right;
      strong {
        display: inline-block;
        padding-top: 1px;
        margin-left: 15px;
        font-size: 13px;
      }
    }
  }
  p.red {
    color: #f00;
  
    text-align: center;
    padding-top: 20px;
    font-weight: 800;
  }
  .modal-body.email-body {
    min-height: 376px;
  }
  .email-sent {
    margin: 70px 0 270px;
    text-align: center;
    color: #303030;
  }
  .sales-tax {
    td {
      background: #fff;
      strong:last-child {
        min-width: 55px;
      }
    }
  }
  .customer-info.notes{
    padding-left: 14px;
  }
  .re-pdt-cnt.invdetail-cnt .display-header1> strong {
    font-size: 12px !important;
  }
  .re-pdt-cnt.invdetail-cnt .pdf-body > p.imp-note{
    padding-bottom: 6px;
  }
  .display-header,.display-header1,.display-header2{
    padding: 0 10px;
  }
  .pdf-header .customer-info{
    >div{
      font-size: 11px;
    }
  }
  .pdf-body{
    padding-top: 12px;
    border-top: 4px solid #ccc;
  }
  .display-header,.display-header1{
    background: #999;
      min-height: 24px;
    padding: 5px 10px;
    margin: 8px 8px 12px;
    >span,>strong{
      padding-top: 3px;
      display: inline-block;
    }
  }
  tfoot tr:first-child td.final-amount{
    padding-top: 30px;
  }
  @media print {
    .pdf-body{
      padding-top: 0;
      border-top:1px solid #fff;
    }
    .display-header,.display-header1{
      background: none;
        min-height: 24px;
      padding: 0 10px;
      margin: 0;
      >span,>strong{
        padding-top: 0;
        display: inline;
      }
    }
    div.invoice-body .customer-info > span {
      font-size: 11px;
      margin: 1px;
  }
  .note-cnt > div {
    line-height: 15px;
  }
  .pdf-header .customer-info{
    >div{
      font-size: 10px;
    }
  }
  tfoot tr:first-child td.final-amount{
    padding-top: 0;
  }
}
   