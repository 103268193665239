.small-head {
  font: normal 400 16px Honeywell-Book;
  color: #1d1d1d;
}
.contract-hdr {
  background: #f3f3f3;
  padding: 10px;
  font: normal 700 14px Honeywell-Medium;
  text-transform: uppercase;
}
.contract-list-cnt {
  min-height: 300px;
  max-height: 300px;
  overflow-y: auto;
  .contract-num {
    font: normal 500 16px Honeywell-Medium;
    color: #0071b3;
    padding: 10px;
    border-bottom: 1px solid #e9e9e9;
    input {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
    a {
      position: relative;
      top: -2px;
      left: 5px;
      cursor: pointer;
    }
  }
}

.no-data-contract {
  text-align: center;
  // padding: 20px 10px;
}
