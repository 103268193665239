@import "../../../../variables.scss";
.reportpopup-cnt {
  width: 1200px;
  height: 100%;
  .report-usg-cnt {
    h2 {
      text-align: center;
      position: relative;
      top: 40px;
      color: $gray-80;
    }
  }
}
