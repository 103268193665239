.upload-validate {
  padding: 50px 0;
  text-align: center;
  p {
    font: 16px "Honeywell-Book";
    color: #1d1d1d;
    text-align: center;
    padding-bottom: 30px;
  }
  .progress {
    width: 60%;
    margin: auto;
    border-radius: 10px;
  }
}
