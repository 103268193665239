.invoice-success {
  margin-bottom: 20px;
  text-align: center;
  padding-top: 0;

  &+.modal-footer {
    justify-content: center;
    padding-bottom: 50px;
  }
}

.success-close {
  .modal-title {
    margin-top: 60px;
  }
}

.submit-cnt {
  margin: 181px 0 30px;
}

.loading-msg {
  h3 {
    margin-top: 40px;
  }

  .progress {
    width: 60%;
    margin: 40px auto;
    border-radius: 10px;
  }
}

.accept-modal {
  min-height: 100px;

  .accept-modal-body {
    text-align: left;
    padding-left: 10px;
  }

  .goback-message {
    padding-left: 24px;
  }

  h6{
    font: 500 16px "Honeywell-Medium";
  }

  .checkbox-label {
    display: flex;
    margin-bottom: 0px;
    align-items: baseline;

    input[type=checkbox] {
      margin-right: 10px;
    }

    .checkbox {
      transform: scale(1.5);
    }
  }
}

.accept-modal-display {
  display: none;
}