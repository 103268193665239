.confirmation-container {
  width: 500px;
  height: 580px;
  position: relative;
  scroll-behavior: auto;

  .title-modal {
    width: 100%;
    height: 220px;
    margin: auto;
    margin-top: 12px;
    text-align: center;
    .credits-img {
      width: 100%;
      height: 65px;
      margin: auto;
      img {
        width: 35%;
        margin-top: 5px;
      }
    }
    .credits-title {
      width: 100%;
      height: 155px;
      margin: auto;
      margin-top: 10px;
      .credits-submit {
        width: 100%;
        height: 40%;
        p {
          font: 700 28px "Honeywell-Medium";
          color: #1d1d1d;
        }
      }
      .credits-reference{
        width: 100%;
        height: 35%;
        display: flex;
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
          font: 700 16px "Honeywell-Medium";
          color: #303030;
          padding: 2px;
        }
      }
    }
  }

  .modal-body{
    overflow-y: auto;
    min-height: 255px;
  }

  .invoice-applied {
    width: 78%;
    margin: auto;
    .selected-credits {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #c0c0c0;
      .selected-credits-hdr {
        width: 100%;
        margin-bottom: 5px;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }

      .credits-details {
        width: 100%;
        overflow-y: auto;
        .credits-data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }

    .invoice-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #c0c0c0;

      .selected-invoice-hdr {
        width: 100%;
        margin-bottom: 5px;
        margin-top: 10px;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }

      .invoice-details {
        width: 100%;
        overflow-y: auto;
        .invoice-data {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }

    .remaining-credit {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 10px;
      span {
        font: normal 700 16px "Honeywell-Medium";
        color: #303030;
      }
    }
  }
}

.credits-loading-msg {
  width: 100%;
  height: 35%;
  color: #303030;
  h2 {
    text-align: center;
  }
}

.credits-error-msg{
  width: 90%;
  height: 55%;
  margin: auto;
  p{
    width: 100%;
    height: 100%;
    font: normal 700 16px "Honeywell-Medium";
    color: #303030;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.applied-credits-modal {
  background: transparent;
}
