/*color variables*/
$gray-20: #f3f3f3;
$gray-40: #f0f0f0;
$gray-50: #d0d0d0;
$gray-60: #8c8c8c;
$gray-70: #6d6d6d;
$gray-80: #303030;
$gray-90: #1d1d1d;
$blue-60: #0071b3;
$red: #f00;
$orange: #f5a623;

$white: #fff;
$black: #000;

/*font variables */
$font-11: 11x;
$font-12: 12x;
$font-14: 14x;
$font-16: 16x;
$font-18: 18x;
$font-20: 20x;
$font-22: 22x;
$font-28: 28x;
$font-30: 30x;
$font-34: 34x;
$font-40: 40x;

/*common values */
$w-10px: 10px;
$w-20px: 20px;
$w-30px: 30px;
$w-40px: 40px;
$w-50px: 50px;
$h-10px: 10px;
$h-20px: 20px;
$h-30px: 30px;
$h-40px: 40px;
$h-50px: 50px;

$white: #fff;
$black: #000;
$blue: #1274b7;
$blue1: #1792e5;
$contrast-blue: #137bc0;
$blue2: #0e5f95;
$darkBlue: #1272b2;
$blue-hov: #00629b;
$green: #7eb338;
$green2: #628b2c;
$red: #ee3124;
$red1: #f15a4f;
$red2: #cc0000;
$fw-light: 300;
$fw-reg: 500;
$fw-bold: 800;
$fw-xbold: 900;
$fw-xxbold: 700;
$fw-regbold: 800;
$grey1: #707070;
$grey2: #d0d0d0;
$grey3: #9b9b9b;
$grey4: #808080;
$grey5: #d7d7d7;
$grey6: #f7f7f7;
$grey7: #e0e0e0;
$grey8: #404040;
$grey9: #a0a0a0;
$grey10: #979797;
$grey11: #d7d7d7;
$grey12: #595858;
$grey13: #d8d8d8;
$grey14: #f0f0f0;
$grey15: #6c6c6c;
$grey16: #e8e8e8;
$grey17: #6e6e6e;
$grey18: #e6e6e6;
$grey19: #4a4a4a;
$grey20: #d7d7d7;
$grey21: #c0c0c0;
$grey21: #cccccc;
$grey22: #bababa;
$grey23: #e7e7e7;
$grey24: #e5e5e5;
$grey25: #e4e4e4;
$grey26: #f8f8f8;
$grey27: #eee;
$grey28: #777;
$grey29: #757575;
$grey30: #f9f9f9;
$grey31: #d0e9f9;
$grey32: #c9c7c7;
$grey33: #c4c4c4;
$grey34: #b0b0b0;
$grey-dark1: #303030;
$grey-dark2: #606060;
$grey-dark3: #505050;
$grey-dark4: #3c3c3c;
$grey-dark5: #535353;
$grey-dark6: #464545;
$grey-dark7: #282c34;
$grey-dark8: #303130;
$grey-bg: #f6f6f6;
$grey-bg1: #f2f4f7;