@import "../../../variables.scss";
.pdf-cnt{
    .info-txt1{
        margin-left: 10px;
    }
}

.min-max{
    position: absolute;
    right: 55px;
    top: 8px;    
}
.max-window{
    .modal-dialog.modal-dialog-centered {
        max-width: 100%;
        min-width: 500px;       
        margin: 0;
        height: 100%;
        .modal-content{
          width: 100%;
          height: 100%;
          margin: auto;         
          .re-pdt-cnt.invdetail-cnt.pdf-cnt{
            min-height: 360px;
            height: auto;
            max-height: 100%;         
        }
        .min-max>a{
            position: relative;
            width: 21px;
            height: 21px;
            top: 3px;
            display: inline-block;
            border:2px solid #9b9b9b;
            &::after{
                content: "-";   
                position: absolute;
                top: -8px;
                left: 5px;
                font-size: 20px;
                color: #9b9b9b;
            }
            
            cursor: pointer;     
            img{
                display: none;
            }
        }
        }
      }
}
.invoice-total{
    width: 98%;
    td{
        padding-bottom: 14px;
    }
}
.note-list{
    padding-left: 25px;
    li{
        font: normal 500 12px "Honeywell-Medium";
        &.no-list{
            list-style: none;
            margin-left: -14px;
        }
    }
}

.invoice-btn{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .cancel-btn{
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-transform: uppercase;
        cursor: pointer;
        span{
            font: 700 14px "Honeywell-Medium";
            color: #007bc2;
        }
        .invcancel-text{
            font: 700 14px "Honeywell-Medium";
            color: $gray-80;
        }
    }
    .cancel-disabled{
        width: 40%;
        cursor: pointer;
        span{
           display: none;
        }
    }
    .paynow-btn{
        width: 60%;
        display: flex;
        justify-content: flex-end;
    }
}
@media print { 
    .invoice-total{
        width: 96.5%;
    }
}