.viewstate-hdr,
.viewstate-mn .date-filter {
  display: flex;
  justify-content: space-between;
}
.viewstate-data .table td {
  width: 10%;
  font: normal 500 14px "Honeywell-Medium";
  text-align: left;
}
.rc-results-wrapper11 {
  .rc-results1 {
    .total {
      position: absolute;
      right: 20px;
    }
  }
}
