@import '../../../variables.scss';
.report-tbl-header {
  width: 100%;
  min-height: 64px;
  display: flex;
  background: #f3f3f3;
  align-items: center;
  margin-top: 20px;
  > div {
    width: 15%;
    min-height: 40px;
    padding: 10px;
    font: normal 700 14px "Honeywell-Medium";
    color: #1d1d1d;
    text-transform: uppercase;
    &:nth-child(4) {
      width: 11%;
    }
    &:nth-child(2) {
      width: 12%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:last-child {
      width: 8%;
    }
  }
}
.info-icon {
  margin-left: 5px;
  top: -3px;
}
.report-tbl-body {
  min-height: 300px;
}
.report-tbl-row {
  border-bottom: 1px solid #e9e9e9;
  .report-tbl-lft {
    float: left;
    width: 14%;
    align-items: self-start;
    a {
      font: normal 500 16px Honeywell-Medium;
      text-decoration: none;
      cursor: default;
    }
  }
  .report-tbl-rgt {
    width: 90%;
    display: block;
    float: left;
    border-bottom: 1px solid #e9e9e9;
    &:last-child {
      border-bottom: 0;
    }
    .report-rgtinr-1 {
      width: 30%;
      float: left;
      display: flex;
      > div {
        width: 50%;
        text-align: left;
        word-break: break-all;
        &:first-child {
          color: #303030;
          font: normal 800 16px Honeywell-Bold;
        }
      }
    }
    .report-rgtinr-2 {
      width: 68%;
      float: left;
    }
  }
}


.report-rgtinr,
.report-rgtinr-sub {
  width: 100%;
  float: left;

  > div {
    width: 20%;
    float: left;
    text-align: left;
  }
}
.report-rgtinr-sub {
  margin-bottom: 25px;
  > div {
    input.active-red {
      border: 1px solid #f00;
      background: #ffe2e2;
      color: #f00;
      &::placeholder {
        color: #f00;
      }
    }
    &.active-red {
      color: #f00;
    }
    &:nth-child(2) {
      width: 23%;
      min-height: 20px;
      font-size: 14px;
      padding-top: 8px;
    }
    &:nth-child(3) {
      position: relative;
      width: 25%;
      .validation-text {
        position: absolute;
        color: #f00;
        font-size: 12px;
        left: -2px;
        width: 400px;
      }
    }
    &:nth-child(4) {
      position: relative;
      width: 20%;
      .validation-text {
        position: absolute;
        color: #f00;
        font-size: 12px;
        left: -130px;
        width: 400px;
      }
    }
    &:last-child {
      width: 12%;
      color: #0071b3 !important;
      font: normal 700 12px "Honeywell-Medium";
      text-transform: uppercase;
      word-break: keep-all;
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  div > span + br + span {
    display: block;
    font-size: 10px;
  }
}

/* css for home page */
.report-tbl-header {
  min-height: 64px;
  display: flex;
  background: #f3f3f3;
  align-items: center;
  margin-top: 20px;
  > div {
    width: 14%;
    min-height: 40px;
    padding: 10px;
    font: normal 700 14px "Honeywell-Medium";
    color: #1d1d1d;
    text-transform: uppercase;
  }
}
.report-tbl-body {
  min-height: 300px;
}
.report-tbl-row {
  display: flex;
  // align-items: center;
  > div {
    min-height: 60px;
    display: flex;
    padding: 10px;
    color: #303030;
    font: normal 400 16px "Honeywell-Medium";
    &:last-child {
      text-align: right;
      > span {
        display: block;
        text-align: right;
        width: 100%;
      }
    }
    div {
      font: normal 400 16px "Honeywell-Medium";
      word-break: break-all;
    }

    .tool-tip {
      width: 254px;
      left: -120px;
      &::before,
      &::after {
        left: 114px;
      }
      > span {
        display: block;
        margin-bottom: 4px;
        font-size: 12px;
        > strong {
          padding-right: 5px;
          font-size: 14px;
        }
      }
    }
  }
  input {
    width: 84px;
    height: 36px;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    color: #303030;
    font: normal 400 16px "Honeywell-Medium";
    text-align: center;
  }
  input[placeholder="End TSN"] {
    width: 103px;
  }
  div,
  div span {
    color: #303030;
  }
}

.report-rgtinr {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
  &:last-child {
    border: 0;
  }
}
.show-rental-option {
  position: absolute;
  width: 170px;
  z-index: 999;
  font-size: 12px;
  background: #fff;
  padding-bottom: 10px;
  margin-top: 4px;
  > a {
    color: #0071b3 !important;
    font: normal 700 12px "Honeywell-Medium";
    text-transform: uppercase;
    padding-bottom: 6px;
    display: block;
  }
  .rental-select {
    width: 159px !important;
    height: 28px;
    padding: 2px;
    border: 1px solid #d9d9d9;
    margin: 1px 0;
    border-radius: 4px;
    color: #303030;
    font: normal 500 14px "Honeywell-Medium";
  }
}

.lable-for-rental,
.rental-select {
  font-size: 12px;
}

.show-rental-option-hidden {
  display: none;
}
.notail-msg {
  position: absolute;
  color: #f00 !important;
  font: normal 600 14px "Honeywell-Medium";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  //  / -webkit-appearance: none;
  margin: 0;
}

.btn-pre-invoice {
  width: 48%;
}

.btn-pre-invoice:hover {
  color: #0d6efd !important;
  background-color: #ffff !important;
}

.submit-pre-invoice {
  text-align: right;
  width: 82%;
  .btn {
    text-transform: uppercase;
  }
  .input-check {
    top: 8px;
    label {
      margin-left: 30px;
      font: normal 700 16px "Honeywell-Medium";
      color: $red;
      cursor: pointer;
      word-break: break-word;
      text-align: left;
    }
  }
  .input-check > input[type=checkbox] {
    position: absolute;
    &::before {
      top: 4px !important;
      left: 7px !important;
    }
    &::after {
      top: 0px !important;
    }
  }
}
.contarct-lable {
  cursor: pointer !important;
}
.hide-rental {
  pointer-events: none;
  color: grey;
}