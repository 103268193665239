@import "../../../variables.scss";

.invoice-cnt {
  background: #fff;
  margin: 20px 0;
  min-height: 540px;
  padding: 20px;
  .invoice-hdr {
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;
    .btn-blue {
      float: right;
    }
    .download-usage,
    .download-usage-hidden {
      float: right;
      margin-top: 10px;
      .loader {
        position: absolute;
        top: 6px;
        right: 198px;
        width: 28px;
        height: 28px;
        border-top: 4px solid #3498db;
      }
    }
  }
  .paginate-cnt {
    .pagination {
      margin-top: 18px;
      float: right;
    }
  }
  .search-block {
    width: 244px;
  }
  .btn-open-close,
  .btn-open-close:active {
    width: 200px;
    padding: 7px;
    font: normal 700 14px "Honeywell-Bold";
    text-align: center;
    margin-left: 12px;
  }
  .page-count {
    font: normal 500 14px "Honeywell-Medium";
    color: #606060;
    display: flex;
    margin: 25px 0px 21px;
  }
  .invoice-info {
    border-top: 1px solid #c0c0c0;
    padding-bottom: 10px;
    margin-left: 10px;
    .info-hdr {
      position: relative;
      margin-top: 10px;
      padding-top: 10px;
      display: flex;

      .input-check {
        position: absolute;
        top: 11px;
        left: -25px;
        input {
          width: 20px;
          height: 26px;
        }
        input[type="checkbox"]:checked:after {
          top: 3px;
        }
      }
      .contract-hdr-cnt {
        width: 100%;
        display: flex;
        .contract-hdr-num {
          width: 28%;
          .contract-num {
            font: normal 700 20px "Honeywell-Medium";
            color: #000;
            display: inline-block;
            margin-bottom: 8px;
          }
          .single-tail {
            font: normal 700 16px "Honeywell-Medium";
            color: #000;
            display: flex;
            .blank {
              margin-top: 20px;
            }
          }
          .multiple-tail {
            font: normal 500 16px "Honeywell-Medium";
            display: flex;
            align-items: center;
            color: #0071b3;
            cursor: pointer;
            .mtail-icon {
              margin: 5px 5px 3px 5px;
            }
          }
        }

        .contract-hdr-status {
          width: 32%;
          .status-container {
            .label {
              display: inline;
              padding: 6px 8px;
              font: normal 700 14px "Honeywell-bold";
              color: #ffff;
              text-align: center;
              white-space: nowrap;
              vertical-align: baseline;
              border-radius: 0.25em;
              text-transform: uppercase;
              .tooltiptext {
                position: absolute;
                padding: 6px;
                width: 239px;
                height: 76px;
                z-index: 1;
                left: 218px;
                background: $white;
                color: $gray-90;
                font: normal 500 14px "Honeywell-Book";
                top: -80px;
                border: 1px solid #ccc;
                text-align: left;
                display: none;
                border-radius: 4px;

                &::after {
                  position: absolute;
                  width: 0;
                  height: 0;
                  bottom: -13px;
                  left: 101px;
                  content: "";
                  border-left: 12px solid transparent;
                  border-right: 12px solid transparent;
                  border-top: 12px solid #ccc;
                }

                &::before {
                  position: absolute;
                  width: 0;
                  height: 0;
                  bottom: -11px;
                  left: 101px;
                  z-index: 1;
                  content: "";
                  border-left: 12px solid transparent;
                  border-right: 12px solid transparent;
                  border-top: 12px solid $white;
                }
                > span {
                  display: block;
                  margin-bottom: 4px;
                  font-size: 14px;
                  color: $gray-80;
                  font-family: "Honeywell-Medium";
                  font-weight: 500;
                  white-space: pre-line;
                  text-transform: none;
                  > strong {
                    padding-right: 5px;
                    font-size: 13px;
                  }
                }
              }
            }

            .label:hover .tooltiptext {
              display: block;
            }

            .label-success {
              background-color: #7eb338;
            }
            .label-default {
              background-color: #303030;
            }
            .label-danger {
              background-color: #be271c;
              img {
                margin-right: 8px;
              }
            }
            .label-info {
              background-color: #1792e5;
              img {
                margin-right: 7px;
              }
            }
          }
          .transfer-st-cnt {
            border: none;
            background-color: $blue1;
            border-radius: 3px;
            padding: 0px 6px 4px;
            display: inline-block;
            .transfer-img {
              img {
                width: 20px;
                margin-right: 2px;
              }
            }
            .transfer-text {
              font: normal 700 12px "Honeywell-Medium";
              text-transform: uppercase;
              color: $white;
            }
          }
        }

        .contract-hdr-btn {
          width: 40%;
          display: flex;
          button.btn-white.transfer-status-bt.btn.btn-primary {
            padding: 6px 10px;
            font-size: 12px;
            border-radius: 3px;
          }
          div {
            width: 95%;
            display: flex;
            align-items: flex-start; 
            justify-content: flex-end;
          }
          div:nth-child(2) {
            width: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            .context-menu {
              cursor: pointer;
              img {
                padding: 5px;
              }
            }
            .context-menu-btn {
              size: 120px;
              display: block;
              position: absolute;
              right: -0;
              top: 65px;
              box-shadow: 5px 5px 5px #dddd;
              border: none !important;
            }
          }
        }

        .msp-tag {
          font: normal 700 12px "Honeywell-Medium";
          border-radius: 10px;
          padding: 4px;
          color: #fff;
          margin: 10px;
          display: none;
        }
        .tag-orange {
          @extend .msp-tag;
          background: #eaa844;
        }

        .tag-blue {
          @extend .msp-tag;
          background: #4192df;
        }

        .tag-grey {
          @extend .msp-tag;
          background: #606060;
        }
      }

      .contract-amt {
        width: 800px;
        font: normal 800 15px "Honeywell-Medium";
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        .pre-pay {
          margin-right: 15px;
          color: #606060;
        }
        .pre-pay-value {
          color: #606060;
          font: normal 500 14px "Honeywell-Medium";
        }
      }

      .btn-pay {
        padding: 0px 15px 0px 15px;
        .btn {
          font: normal 700 14px "Honeywell-light";
          margin: 0;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
  }
  .contract-details-container {
    display: flex;
    margin-top: 18px;
    .contract-details {
      width: 24%;
      margin-right: 14px;
      span {
        font: normal 700 15px "Honeywell-Medium";
        color: #303030;
        display: block;
        padding-bottom: 6px;
        button {
          background: none;
          border: none;
          font: 700 15px "Honeywell-Medium";
          cursor: pointer;
          color: $blue-60;
        }
        &:last-child {
          color: #606060;
          font: normal 500 14px "Honeywell-Medium";
        }
        &.contract-no {
          color: #0071b3;
        }
      }
      &:first-child {
        width: 27%;
      }
      &:last-child,
      &:nth-child(4) {
        width: 20%;
      }
    }
    .contract-rate {
      text-decoration: none;
      font: normal 500 16px "Honeywell-Medium";
      color: #0071b3;
      padding-top: 0px;
      display: block;
    }
    .contract-date {
      padding-left: 45px;
    }
  }
}

.tail-row {
  height: 38px;
  background: #f3f3f3;
  display: flex;
  div {
    font: normal 700 14px "Honeywell-Medium";
    text-transform: uppercase;
    padding: 10px;
    color: #1d1d1d;
    &:first-child {
      width: 30%;
    }
    &:last-child {
      width: 68%;
    }
  }
}

.tail-num {
  display: flex;
  border-bottom: 1px solid #e9e9e9;
  > span {
    padding: 10px;
    color: #030303;
    &:first-child {
      width: 30%;
      font: normal 800 16px "Honeywell-Medium";
      span.info-icon {
        top: -3px;
      }
    }
    &:last-child {
      width: 68%;
      font: normal 500 16px "Honeywell-Medium";
    }
  }
}
.tail-cnt-row {
  min-height: 260px;
}
.tail-num .info-icon {
  .tool-tip {
    width: 216px;
    left: -79px;
    &::before,
    &::after {
      left: 74px;
    }
  }
}

.select-btns {
  text-align: right;
}

.btn {
  margin-right: 10px;
}

.load-more {
  text-align: center;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 30px;
}
.disebled {
  pointer-events: none;
  background: #bdbfc2;
}
.paginate-cnt {
  margin: 12px 0px 10px;
}
a {
  text-decoration: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
.top-btns {
  margin: 15px -20px 15px 0;
}
.view-state {
  position: absolute;
  right: 0;
  top: 36px;
}

.drop-down {
  width: 100%;
  font: normal 500 14px "Honeywell-Medium";
}

.btn.btn-white.context-menu-btn:hover {
  background: #f1f0f0;
  color: #0071b3;
  box-shadow: 5px 5px 5px #dddd;
}