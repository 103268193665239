@import '../../../../variables.scss';
.credits-notes{
    display: flex;
    span{
        font: normal 500 14px "Honeywell-Medium";
        color: $red;
    }
    margin-bottom: 20px;
}
.credits-container {
    width: 1000px;
    height: 550px;
    position: relative;
    scroll-behavior: auto;
    right: 50%;

    .modal-body {
        overflow-y: scroll;
        .page-count {
            font: normal 500 14px "Honeywell-Medium";
            color: #606060;
        }
    }

    .invoice-info {
        border-top: 1px solid #c0c0c0;
        padding-bottom: 10px;
        margin-left: 32px;

        .info-hdr {
            position: relative;
            margin-top: 10px;
            padding-top: 10px;
            display: block;

            .input-check {
                position: absolute;
                top: 11px;
                left: -25px;

                input {
                    width: 20px;
                    height: 26px;
                }

                input[type="checkbox"]:checked:after {
                    top: 3px;
                }
            }

            .contract-cnt {
                .contract-num {
                    font: normal 700 20px "Honeywell-Medium";
                    color: #000;
                    display: inline-block;
                    margin-bottom: 8px;
                }

                .single-tail {
                    font: normal 700 16px "Honeywell-Medium";
                    color: #000;
                    display: flex;
                }

                .multiple-tail {
                    font: normal 500 16px "Honeywell-Medium";
                    display: flex;
                    align-items: center;
                    color: #0071b3;
                    cursor: pointer;

                    .mtail-icon {
                        margin: 5px 5px 3px 5px;
                    }
                }

                .msp-tag {
                    font: normal 700 12px "Honeywell-Medium";
                    border-radius: 10px;
                    padding: 4px;
                    color: #fff;
                    margin: 10px;
                    display: none;
                }

                .tag-orange {
                    @extend .msp-tag;
                    background: #eaa844;
                }

                .tag-blue {
                    @extend .msp-tag;
                    background: #4192df;
                }

                .tag-grey {
                    @extend .msp-tag;
                    background: #606060;
                }
            }

            .invoice-amt {
                margin-left: auto;
                font: normal 800 20px "Honeywell-Medium";
                align-items: center;
                text-align: right;
                color: #279d2b;
                text-transform: uppercase;

                span {
                    display: block;
                    margin-bottom: 6px;
                    margin-right: 16px;

                    &:last-child {
                        font-size: 16px;
                    }

                    &.close-value {
                        color: #303030;
                    }

                    &.close-value,
                    &.open-value {
                        span:last-child {
                            font-size: 14px;
                        }
                    }

                    strong {
                        padding-right: 2px;
                    }
                }
            }

        }

        &:last-child {
            border-bottom: 1px solid #e6e6e6;
        }
    }

    .info-ftr {
        display: flex;
        margin-top: 18px;
        justify-content: space-between;

        >div {
            width: 9%;
            // margin-right: 14px;

            span {
                font: normal 700 15px "Honeywell-Medium";
                color: #303030;
                display: block;
                padding-bottom: 6px;

                &:last-child {
                    color: #606060;
                    font: normal 500 14px "Honeywell-Medium";
                }

                &.contract-no {
                    color: #0071b3;
                }
            }

            &:first-child {
                width: 38% !important;
            }

            &:last-child,
            &:nth-child(4) {
                width: 13%;
            }
        }
    }

    button.btn.btn-blue.disabled.btn.btn-primary {
        background: #dfdfdf !important;
        color: #606060 !important;
        border: 1px solid #dfdfdf !important;
    }
}

.credits-container-display{
    display: none;
}