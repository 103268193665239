.applied-credits-container {
  width: 545px;
  height: 450px;
  position: relative;
  scroll-behavior: auto;
  left: 0;
  top: 60px;

  .title-modal {
    p {
      color: #606060;
      font: normal 500 14px "Honeywell-Medium";
    }
  }

  .modal-body {
    min-height: auto;
  }

  .invoice-applied-container {
    font: normal 400 20px "Honeywell-Medium";
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    width: 90%;
    height: 200px;
    margin: auto;

    .invoices-details {
      width: 100%;
      height: 86%;
      overflow-y: auto;
      border-bottom: 1px solid #c0c0c0;

      .selected-credit-ammount {
        width: 100%;
        height: 25%;
        padding-bottom: 10px;
        display: flex;
        border-bottom: 1px solid #c0c0c0;

        .selected-credit-ammount-label {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            font: normal 700 16px "Honeywell-Medium";
            color: #303030;
          }
        }

        .selected-credit-ammount-value {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 5px;

          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }

      .selected-invoice-details {
        width: 100%;
        height: 25%;
        display: flex;

        .selected-invoice-details-hdr {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            font: normal 700 16px "Honeywell-Medium";
            color: #303030;
          }
        }

        .selected-invoice-label {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }

        .selected-invoice-value {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 5px;

          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }

    .remaining-credit-ammount {
      width: 100%;
      height: 25%;
      display: flex;

      .remaining-credit-ammount-label {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }

      .remaining-credit-ammount-value {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 5px;

        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }
    }
  }
}

.applied-credits-modal {
  background: transparent;
}

.applied-credits-container-display {
  display: none;
}
