.selected-credits-container {
  width: 545px;
  height: 400px;
  position: relative;
  scroll-behavior: auto;
  left: 0;
  top: 60px;

  .title-modal {
    p {
      color: #606060;
      font: normal 500 14px "Honeywell-Medium";
    }
  }

  .credits-total-container {
    width: 90%;
    height: 100%;
    margin: auto;
    .credit-invoice-details {
      width: 100%;
      height: 86%;
      overflow-y: auto;
      border-bottom: 1px solid #c0c0c0;
      .credit-invoice {
        width: 100%;
        height: 18%;
        display: flex;
        .credit-invoice-label {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            font: normal 700 16px "Honeywell-Medium";
            color: #303030;
          }
        }
        .credit-invoice-value {
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-right: 5px;
          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }
    .credit-invoice-total {
      width: 100%;
      height: 25%;
      display: flex;
      .credit-invoice-total-label {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }
      .credit-invoice-total-value {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 5px;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }
    }
  }
}
.selected-credits-modal {
  background: transparent;
}
.selected-credits-display{
  display: none;
}