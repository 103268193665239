$white: #fff;
$white-80:#f3f3f3;
$black: #000;
$blue-80:#0b4872;
$blue-70:#00629b;
$blue-60: #0071b3;
$blue-50:#007bff;
$green: #28a745;
$war: #e0b61e;
$orange-80:#ff8c00;
$red: #f00;
$gray-40: #f0f0f0;
$gray:#303030;
$gray-90: #1d1d1d;
$gray-80: #606060;
$gray-100:#495057;
$gray-60:#c0c0c0;
$gray-50:#dfdfdf;
$gray-70:#ccc;

a {
  color: $blue-60;
  cursor: pointer;
}

input[type="search"] {
  background: $gray-40;
}

input[type="file"] {
  cursor: pointer;
}

h1,
h2,
h3,
h3 {
  font-weight: 700;
  color: $gray-90;
}

h1 {
  font: normal 700 40px "Honeywell-Medium";
}

h2 {
  font: normal 700 28px "Honeywell-Medium" !important;
}

h3 {
  font: normal 700 20px "Honeywell-Medium" !important;
}

h4 {
  font: normal 700 16px "Honeywell-Medium";
}

.m-top-10 {
  margin-top: 10px;
}

.m-top-20 {
  margin-top: 20px;
}

.m-top-30 {
  margin-top: 30px;
}

.m-top-40 {
  margin-top: 40px;
}

.m-btm-10 {
  margin-bottom: 10px;
}

.m-btm-20 {
  margin-bottom: 20px;
}

.m-btm-30 {
  margin-bottom: 30px;
}

.m-btm-40 {
  margin-bottom: 40px;
}
input:disabled {
  pointer-events: none;
}
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
.dropdown{
  width: 96%;
  padding: 6px;
  height: 38px;
  border: 1px solid $gray-60;
  border-radius: 4px;
  font: italic 500 14px "Honeywell-Book";
  color: $gray-80;
  outline: none;
  &:focus {
    background-color: $white;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}
}
.input-rdb {
  position: relative;
  input[type="radio"]:checked {
    &:after {
      position: absolute;
      width: 17px;
      height: 17px;
      border-radius: 15px;
      background-color: $white;
      content: "";
      border: 1px solid $blue-50;
      left: 0px;
      top: 0;
    }
    &:before {
      position: absolute;
      width: 11px;
      height: 11px;
      border-radius: 15px;
      background-color: $blue-50;
      content: "";
      z-index: 1;
      top: 3px;
      left: 3px;
    }
  }
}
.input-check {
  position: relative;
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  input[type="checkbox"]:checked {
    &:after {
      position: absolute;
      width: 22px;
      height: 22px;
      background-color: $white;
      content: "";
      border: 1px solid $blue-50;
      top: 4px;
      left: 0;
    }
    &:before {
      left: 8px;
      top: 7px;
      width: 8px;
      height: 14px;
      border: solid $blue-50;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
      content: "";
      z-index: 1;
      position: absolute;
    }
  }
}
.err {
  font: normal 500 14px "Honeywell-Book";
  color: $red !important;
}
.modal-header {
  border-bottom: 0 !important;
  position: relative;
  .btn-close {
    position: absolute;
    top: 13px;
    right: 15px;
  }
}
.btn.btn-white,
.btn.btn-blue {
  min-width: 140px;
  min-height: 28px;
  text-transform: uppercase;
  text-align: center;
  padding: 12px;
  font: normal 700 14px "Honeywell-Bold";
  cursor: pointer;
}

.btn.btn-blue,
.btn.btn-blue:hover {
  background: $blue-60;
  color: $white;
}

.btn.btn-blue > div,
.btn.btn-white > div {
  font: normal 700 16px "Honeywell-Bold";
  padding: 0;
}

.btn.btn-white,
.btn.btn-white:hover {
  background: $white;
  color: $blue-60;
  border: 1px solid $blue-60;
}

.modal-body {
  min-height: 200px;
  p {
    font: normal 400 15px "Honeywell-Book";
  }
}

.btn-blue:focus,
.btn-white:focus,
.modal-cancel:focus,
.modal-upload:focus {
  outline: none;
  box-shadow: none !important;
}

div.modal-footer {
  border: 0;
}

.success-close {
  .modal-title {
    width: 100%;
    text-align: center;
  }
}
span.info-icon {
  position: relative;
  width: 15px;
  height: 15px;
  top: -1px;
  border-radius: 14px;
  display: inline-block;
  text-align: center;
  text-transform: lowercase;
  font-size: 11px;
  line-height: 11px;
  color: $blue-60 !important;
  text-transform: none;
  cursor: pointer;

  .tool-tip {
    position: absolute;
    padding: 6px;
    width: 220px;
    height: 76px;
    z-index: 1;
    left: -106px;
    background: $white;
    color: $gray-90;
    font: normal 500 14px "Honeywell-Book";
    top: -88px;
    border: 1px solid $blue-60;
    text-align: left;
    display: none;
    border-radius: 4px;

    &::after {
      position: absolute;
      width: 0;
      height: 0;
      bottom: -13px;
      left: 101px;
      content: "";
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $blue-60;
    }

    &::before {
      position: absolute;
      width: 0;
      height: 0;
      bottom: -12px;
      left: 101px;
      z-index: 1;
      content: "";
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid $white;
    }
  }
}

.status-green,
.status-war,
.status-red {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 13px;
}

.info-icon:hover .tool-tip {
  display: block;
}

.upload-usage > span.err {
  font: normal 500 14px "Honeywell-Book";
  color: $red;
}

.loading-msg {
  h3 {
    position: relative;
    top: -8px;
    text-align: center;
    color: #303030;
  }
}

*:disabled {
  background: $gray-50 !important;
  color: $gray-80 !important;
  border: 1px solid $gray-50 !important;
}

.loader {
  position: relative;
  top: 9px;
  border: 6px solid $white-80;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 35px;
  height: 35px;
  margin-left: 6px;
  display: inline-block;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tool-tip.list-data {
  > span {
    display: block;
    padding-bottom: 6px;
  }
}
.tool-tip.list-data {
  > span {
    display: block;
    padding-bottom: 6px;
  }
}
.no-data-contracts,
.no-data-invoices,
.no-data-contracts {
  text-align: center;
  padding: 150px 10px;
  color: #303030;
}
.tbl-hdr {
  width: 100%;
  min-height: 38px;
  display: flex;
  background: $white-80;
  align-items: center;
  margin-top: 20px;
  > div {
    font-weight: 700;
    text-transform: uppercase;
    color: $gray-90;
  }
}

button.btn.btn-sml {
  min-width: 80px;
  padding: 10px;
  font-size: 12px;
}
.tbl-cnt {
  width: 100%;
  overflow: auto;
  min-height: 70px;
  .tbl-row {
    display: flex;
    border-bottom: 1px solid $gray-70;
    > {
      color: $gray;
    }
  }
}
.p-btm-20 {
  padding-bottom: 16px;
}
.modal-dialog.modal-dialog-centered {
  max-width: 66%;
  min-width: 500px;
  margin-left: 17%;
  margin-right: 17%;
}
.disclaim,
.re-pdt-cnt .pdf-body p.disclaim {
  font: italic 500 12px "Honeywell-Medium";
}
.blue {
  color: $blue-60;
}
.red {
  color: $red;
}
.green {
  color: $green;
}
.setSavFavBxDv {
  display: block !important;
}

#toggleCCFormDefaultBtnsDvId {
  display: none !important;
}
.hw-pdf-logo {
  width: 210px;
  img {
    width: 207px;
  }
}
.pagination {
  .page-item {
    a.page-link {
      color: $black;
      border: 0;
      border-radius: 30px;
      padding: 1px 7px;
      margin: 0 4px;
      font-size: 13px;
      &:hover,
      &.disebled {
        background: none;
      }
    }
    &.active {
      a.page-link {
        color: $white;
        background:$blue-60;
        border: 1px solid $blue-60;
      }
    }
    &:first-child a,
    &:last-child a {
      position: relative;
      font-size: 22px;
      top: -9px;
      margin: 0;
      padding: 2px 0;
    }
  }
}
.warn-cnt {
  font: italic 500 14px "Honeywell-Book" !important;
}
.warn-icon,
.err-icon,
.wrong-msg {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 2px solid $orange-80;
  border-radius: 20px;
  color: $orange-80;
  font: normal bold 14px "Honeywell-Medium";
  text-align: center;
  transform: rotateZ(180deg);
  margin-right: 6px;
}
.err-icon {
  border: 2px solid $red;
  color: $red !important;
}
.wrong-msg {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  font-size: 40px;
  border: 2px solid $gray-100;
  color: $gray-100 !important;
  margin-bottom: 18px;
}
.btn-p2 {
  background: $blue-70;
}
.btn-p3 {
  background: $blue-80 !important;
}
.page-load {
  margin-top: 100px;
  text-align: center;
  h2{
    color: #303030;
  }
  
}
.download-usage,
.download-usage-hidden {
  display: inline-block;
  font: 16px "Honeywell-Bold";
  margin: 30px 0 40px;
  color: $blue-60;
  text-transform: uppercase;
  img {
    position: relative;
    width: 22px;
    top: -3px;
    margin-right: 4px;
  }
}
.download-usage-hidden {
  cursor: progress;
}
.search-block {
  width: 390px;
}
.search-cnt {
  position: relative;
   input {
    width: 100%;
    height: 38px;
    padding: 8px;
    border: 1px solid $gray-60;
    border-radius: 4px;
    font: italic 500 14px "Honeywell-Book";
    padding-right: 40px;
    color: $gray-80;
  }
  .search-icon {
    position: absolute;
    z-index: 9;
    right: 5px;
    top: 4px;
    padding: 4px;
    background: $blue-60;
    height: 30px;
    border-radius: 4px;
  }
}
.report_usage {
  .search-block {
    margin: 30px 0 40px;
  }
}
.modal-body .note-info {
  h3{
    color: $red;
  }
  color: $red;
  font: normal 500 16px "Honeywell-Medium";
  border: 1px solid $red;
  padding: 14px;
  margin-top: 14px;
  display: flex;
  align-items: center;
  >.err-icon {
    width: 44px;
    height: 21px;
    margin-right: 12px;
    transform: rotateZ(0deg);
  }
  p{
    font: normal 500 16px "Honeywell-Medium";
    margin-bottom: 8px;
    >span{
      display: block;
      font-weight: 700;
      padding-top: 10px;
    }
  }
}
.notes-cnt {
  min-height: 280px;
}
.imp-note{
  font: normal 800 15px "Honeywell-Medium";
  text-align: right;
  margin: 0 10px !important;
}