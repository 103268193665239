@import '../../../../variables.scss';
.transfercontract-cnt {
  width: 550px;
  height: 700px;

  .title-modal {
    width: 100%;
    height: 365px;
    margin: auto;
    margin-top: 12px;
    text-align: center;
    .transfercontract-img {
      width: 100%;
      height: 65px;
      margin: auto;
      img {
        width: 35%;
        margin-top: 5px;
      }
    }
    .transfercontract-title {
      width: 100%;
      height: 155px;
      margin: auto;
      margin-top: 10px;
      .transfercontract-submit {
        width: 100%;
        height: 60%;
        h1 {
          font-style: "Honeywell-Medium";
          color: $gray-90;
        }
      }
      .transfercnt-texttitle {
        width: 100%;
        height: 15%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
        p {
          font: 700 18px "Honeywell-Medium";
          color: $gray-90;
          padding-top: 10px;
        }
      }
      .transfercnt-text {
        width: 100%;
        height: 55%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          text-indent: 0;
          font: 500 16px "Honeywell-Medium";
          color: $gray-80;
        }
      }
      .transfercnt-textemail {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        p {
          text-indent: 0;
          font: 500 16px "Honeywell-Medium";
          color: $gray-80;
        }
        .email{
          color: $blue-60;
        }
      }
    }
  }
  .transfercntusg-cnt {
    width: 100%;
    height: 200px;
    .transfercnt-usage {
      width: 100%;
      height: 40%;
      display: flex;
      border: 1px solid $orange;
      border-radius: 2px;
      box-shadow: inset 10px 0 0 $orange;
      .transfercnt-img {
        width: 20%;
        img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      .transfercnt-content {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        span:nth-child(1){
          display: block;
          font: 700 14px "Honeywell-Medium";
          color: $gray-80;
          margin-bottom: 5px;
        }
        span{
          display: block;
          font: 500 11px "Honeywell-Medium";
          color: $gray-80;
          margin-bottom: 2px;
        }
      }
    }
    .transfercnt-number{
      width: 100%;
      height: 35%;
      display: flex;
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      span:nth-child(1){
        display: block;
        font: 700 16px "Honeywell-Medium";
        color: $gray-80;
        margin-bottom: 2px;
      }
      span{
        display: block;
        font: 700 18px "Honeywell-Medium";
        color: $gray-80;
      }
    }
    .transfer-btn{
      width: 100%;
      height: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }
}

.transfercontract-display{
  display: none;
}

.transfercnt-backdrop{
  background-color: transparent;
}