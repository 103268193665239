.re-pdt-cnt {
  box-shadow: -2px 2px 5px 5px #8888884a;
  margin: 0px 12px 20px;
  overflow: auto;
  height: 360px;

  .pdf-header {
    display: flex;
    width: 96%;
    color: #000;
    justify-content: space-between;
    .hw-pdf-logo {
      padding: 10px 0px 10px 20px;
      span {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
    }

    .customer-info {
      width: 200px;
      padding: 50px 5px 20px 38px;
      .customer-name {
        font: normal 800 13px "Honeywell-Medium";
        color: #313131;
      }
      span {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
      .email {
        white-space: nowrap;
      }
    }
  }

  .pdf-body {
  &.rate-cnt{
    padding: 20px;
  }
    color: #1d1d1d;
    .subject {
      font: normal 800 14px "Honeywell-Medium";
      color: #000;
    }

    p {
      margin-top: 6px;
      font: normal 600 12px "Honeywell-book";
      color: #1d1d1d;
      &.subject + p > sup {
        top: -1px;
      }
    }
  }
}

.product-table {
  width: 98%;
  margin: 5px auto;

  tr:nth-child(even) {
    background-color: #f3f3f3;
  }
  th,
  td {
    font: normal 600 12px "Honeywell-book";
    padding: 6px;
    &:first-child {
      width: 20%;
    }
  }
  td {
    color: #1d1d1d;
  }
  th {
    color: #000;
    padding-bottom: 8px;
    border-bottom: 1px solid #999;
  }
}
