body {
  font: 12px "Honeywell-Medium";
}
.bg-grey {
  background-color: #e5e5e5;
  margin-bottom: -48px;
}
.no-data-contracts {
  text-align: center;
  padding: 150px 10px;
}
