.ubcredits {
  min-height: 200px;
  background: #fff;
  margin-top: 20px;
  padding: 12px 20px 30px;
  text-align: center;
  font-family: "Honeywell-Bold";
}
.Ub-amt {
  font-size: 30px;
  color: green;
}
.Ub-btn {
  margin-top: 30px;

  .btn.btn-white {
    width: 100%;
  }
}