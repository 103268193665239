@import '../../../../variables.scss';
.progress-cnt{
    width: 1200px;
    height: 550px;
    .contract-usage {
        width: 95%;
        height: 20%;
        display: flex;
        border: 1px solid $orange;
        border-radius: 2px;
        box-shadow: inset 10px 0 0 $orange;
        margin-top: 20px;
        margin-left: 15px;
        .contract-img {
          width: 10%;
          img {
            position: relative;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
        .contract-content {
          width: 85%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          span:nth-child(1){
            display: block;
            font: 700 16px "Honeywell-Medium";
            color: $gray-80;
            margin-bottom: 5px;
          }
          span{
            display: block;
            font: 500 14px "Honeywell-Medium";
            color: $gray-80;
            margin-bottom: 2px;
          }
        }
      }
}

.progresscnt-backdrop{
    background: transparent;
}