.card-details {
  margin: 0;
  ul {
    margin-bottom: 8px;
    li {
      padding-bottom: 3px;
      list-style: none;
      font: normal 500 14px "Honeywell-Medium";
      strong {
        font: normal 600 14px "Honeywell-Medium";
      }
    }
  }
  .pay-date {
    strong {
      font: normal 600 14px "Honeywell-Medium";
    }
  }
  > div.col-9.row > ul {
    width: 49%;
    float: left;
  }
  .pay-date {
    font: normal 500 14px "Honeywell-Medium";
  }
}
.payconfirm-cnt {
  .tbl-hdr,
  .tbl-row {
    > div {
      width: 14%;
      padding: 8px 6px;
      font: normal 700 14px "Honeywell-Medium";
      text-align: center;
      &:nth-child(3) {
        width: 20%;
      }
    }
  }
  .tbl-row > div {
    font: normal 400 14px Honeywell-Medium;
  }
  .tbl-cnt.pay-list {
    overflow: initial;
  }
}
.decline-info {
  h6 {
    color: #303030;
  }
}
