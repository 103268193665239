.ewallet-cnt {
  max-width: 92%;
  margin: 0 auto 26px;
  p {
    text-align: center;
    font: normal 500 16px "Honeywell-Medium";
  }
  .input-rdb {
    > div {
      position: relative;
      input[type="radio"] {
        position: absolute;
        left: 30px;
        top: 0px;
        &:checked:before {
          top: 1px;
          left: 0;
        }
        &:checked:after {
          left: -3px;
          top: -2px;
        }
      }
    }
  }
  .cards-list,
  .card-delet {
    .tbl-cnt {
      max-height: 200px;
      overflow: auto;
    }
    .tbl-hdr > div {
      font: normal 800 12px "Honeywell-Medium";
    }
    .tbl-row > div {
      font: normal 500 14px "Honeywell-Medium";
    }

    .tbl-hdr,
    .tbl-row {
      > div {
        width: 16%;
        padding: 8px 6px;
        .fav {
          padding-left: 15%;
        }
        &:first-child {
          width: 14%;
          text-align: center;
        }
        &:nth-child(2) {
          width: 40%;
        }
        &:nth-child(3) {
          width: 40%;
          text-align: left;
        }
        &:last-child {
          width: 20%;
          text-align: center;
        }
        a {
          color: #0071b3;
          text-decoration: underline;
          margin: 0 3px;
        }
      }
    }
  }

  .delete-card.cards-list {
    .tbl-hdr,
    .tbl-row {
      > div {
        width: 80%;
        text-align: left;
      }
    }
    .card-btn {
      > div {
        width: 100%;
      }
    }
  }
  .card-delet {
    .tbl-row > div:first-child {
      width: 60%;
      text-align: left;
    }
    .tbl-hdr > div:first-child {
      text-align: left;
    }
  }
}

.no-cards {
  text-align: center;
  padding: 30px 0;
}
.modal-body.select-card {
  padding: 0;
}
.cards-hdr {
  display: block;
  padding: 30px 0 0;
  text-align: center;
  > p {
    max-width: 90%;
    margin: auto;
    font-size: 18px;
  }
}
.ccno-card {
  padding: 40px;
}
.ewdelet-cnt {
  width: 100%;
  max-width: 100%;
  min-height: 250px;
}
.card-btn {
  text-align: center;
  button {
    min-width: 120px;
    margin-left: 20px;
  }
}
.edit-card {
  margin-bottom: 0;
  form > div {
    margin-bottom: 17px;
  }
  label {
    font: normal 800 14px "Honeywell-Medium";
  }
  input {
    height: 36px;
    font: normal 500 14px "Honeywell-Medium";
  }
  .input-check {
    margin-top: 16px;
    div,
    label {
      display: inline-block;
    }
    label {
      position: inherit;
      top: 6px;
      left: 10px;
    }
  }
}
.ewaalet-button {
  text-align: right;
  margin-top: 30px;
  margin-bottom: 20px;
}
p.red {
  color: #f00;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 800;
}
.setSavFavBxDv input:hover:after,
.favCardDvBx input:hover:after {
  display: none;
}
div.setSavFavBxDv input[type="checkbox"]:checked:after {
  background: #fff;
  content: "\2714" !important;
  color: #199ed8;
  height: 20px;
  display: block;
  border: 2px solid #199ed8;
}
h6.red{
  max-width: 96%;
  margin:0 auto 12px;
}