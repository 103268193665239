.admin-search {
  width: 500px;
  .search-cnt {
    margin: 10px 0 30px;
  }
  label {
    font: normal 500 16px "Honeywell-Medium";
    color: #313131;
  }
  .soldto-info{
    margin-top: 6px;
    font: italic 400 12px "Honeywell-Medium";
    color: #313131;
  }
}

