@import '../../../../variables.scss';
.cancelinv-cnt {
  margin-bottom: 20px;
  .cancelinv-tbl-header {
    width: 100%;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    div {
      text-align: center;
      padding: 10px 5px;
      width: 20%;
      text-transform: uppercase;
      font: normal 700 14px "Honeywell-Medium";
      background: #f3f3f3;
      color: #1d1d1d;
      &:nth-child(5) {
        width: 25%;
      }
    }
  }

  .loading-inv{
    text-align: center;
    position: relative;
    margin-top: 80px;
    color: $gray-80;
    span{
      font: 700 20px "Honeywell-Medium";
    }
  }

  .cancelinv-tbl {
    width: 100%;
    .cancelinv-tbl-row {
      min-height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      div {
        text-align: center;
        padding: 10px 5px;
        width: 20%;
        font: normal 400 14px "Honeywell-Medium";
        color: #303030;
        &:nth-child(5) {
          width: 25%;
        }
      }
    }
  }
}

.cancelinv-cnt-display{
  display: none;
}
