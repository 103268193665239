.report_usage {
  background: #fff;
  margin: 20px 0;
  min-height: 540px;
  padding: 20px;
  .report-usage-heading {
    text-transform: capitalize;
    color: #1d1d1d;
  }
}
.report-search > input {
  width: 260px;
  height: 30px;
  padding: 8px;
  border: 1px solid #f0f0f0;
  box-shadow: -1px -1px 1px #d9d1d1;
  background: #f0f0f0;
  border-radius: 4px;
  font: italic 500 14px "Honeywell-Book";
}
.no-data-contracts {
  text-align: center;
  padding: 150px 10px;
}
