.rc-results-wrapper {
  .pdf-body {
    padding: 10px;
    color: #000;
    .subject {
      font: normal 800 14px "Honeywell-Medium";
      color: #000;
    }
    p {
      margin-top: 16px;
      font: normal 600 12px "Honeywell-book";
      color: #1d1d1d;
    }
    .product-table {
      width: 750px;
      margin: auto;
      margin-bottom: 10px;
    }
  }
  .pdf-header {
    overflow: hidden;
    width: 100%;
    color: #000;
    .hw-pdf-logo {
      float: left;
      width: 30%;
      padding: 20px 0px 20px 20px;
      span {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
    }
    .customer-info {
      width: 32%;
      float: left;
      padding: 70px 5px 20px 90px;
      margin-left: auto;
      .customer-name {
        font: normal 800 13px "Honeywell-Medium";
        color: #313131;
      }
      span {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
      .email {
        white-space: nowrap;
      }
    }
  }
}
