.viewstate-mn {
  .viewstate-hdr,
  .date-filter {
    display: flex;
    justify-content: space-between;
  }
  > h6 {
    margin: 40px 0 0;
    color: #1d1d1d;
  }
  .viewstate-hdr {
    span {
      font: normal 800 13px "Honeywell-Medium";
      color: #1d1d1d;
    }
    h2 {
      color: #1d1d1d;
    }
  }
  .date-filter {
    width: 82%;
    margin: 20px 0 40px;
    justify-content: normal;
    > div {
      margin-right: 24px;
    }
    > div {
      input {
        width: 200px;
      }
      label {
        font: normal 800 14px "Honeywell-Medium";
        color: #1d1d1d;
      }
      button {
        margin-top: 8px;
      }
    }
  }
  .viewstate-data {
    .table {
      th {
        width: 10%;
        font: normal 800 14px "Honeywell-Medium";
        background: #f3f3f3;
        text-align: left;
        &:nth-child(6) {
          width: 4%;
        }
        &:nth-child(8),
        &:nth-child(5) {
          width: 2%;
        }
        &:last-child,
        &:nth-child(9) {
          width: 14%;
        }
      }
      td {
        width: 10%;
        font: normal 500 14px "Honeywell-Medium";
        text-align: left;
        &:nth-child(5) {
          width: 4%;
        }
        &:nth-child(8),
        &:nth-child(5) {
          width: 2%;
        }
        &:last-child,
        &:nth-child(9) {
          width: 14%;
        }
        > strong {
          margin: 0;
        }
      }
    }
  }
}
.d-none {
  display: none;
}
.btn_invoice {
  width: 500px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.search-btn {
  margin-left: 50px;
}
