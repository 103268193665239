.invoice-cnt {
  background: #fff;
  margin: 20px 0;
  min-height: 540px;
  padding: 20px;

  .invoice-hdr {
    position: relative;
    overflow: hidden;
    margin-bottom: 8px;

    .btn-blue {
      float: right;
    }

    .download-usage,
    .download-usage-hidden {
      float: right;
      margin-top: 10px;

      .loader {
        position: absolute;
        top: 6px;
        right: 198px;
        width: 28px;
        height: 28px;
        border-top: 4px solid #3498db;
      }
    }
  }

  .paginate-cnt {
    .pagination {
      margin-top: 20px !important;
      float: right;
    }
  }

  .search-block {
    width: 244px;
  }

  .btn-open-close,
  .btn-open-close:active {
    width: 200px;
    padding: 7px;
    font: normal 700 14px "Honeywell-Bold";
    text-align: center;
    margin-left: 12px;
  }

  .page-count {
    font: normal 500 13px "Honeywell-Medium" !important;
    color: #606060;
    display: flex;
    margin: 25px -5px 21px 0 !important;
  }

  .invoice-info {
    border-top: 1px solid #c0c0c0;
    padding-bottom: 10px;
    margin-left: 10px;

    .info-hdr {
      position: relative;
      margin-top: 10px;
      padding-top: 10px;
      display: flex;

      .input-check {
        position: absolute;
        top: 11px;
        left: -25px;

        input {
          width: 20px;
          height: 26px;
        }

        input[type="checkbox"]:checked:after {
          top: 3px;
        }
      }

      .contract-cnt {
        .contract-num {
          font: normal 700 20px "Honeywell-Medium";
          color: #000;
          display: inline-block;
          margin-bottom: 8px;
        }

        .single-tail {
          font: normal 700 16px "Honeywell-Medium";
          color: #000;
          display: flex;
        }

        .multiple-tail {
          font: normal 500 16px "Honeywell-Medium";
          display: flex;
          align-items: center;
          color: #0071b3;
          cursor: pointer;

          .mtail-icon {
            margin: 5px 5px 3px 5px;
          }
        }

        .msp-tag {
          font: normal 700 12px "Honeywell-Medium";
          border-radius: 10px;
          padding: 4px;
          color: #fff;
          margin: 10px;
          display: none;
        }

        .tag-orange {
          @extend .msp-tag;
          background: #eaa844;
        }

        .tag-blue {
          @extend .msp-tag;
          background: #4192df;
        }

        .tag-grey {
          @extend .msp-tag;
          background: #606060;
        }
      }

      .invoice-amt {
        margin-left: auto;
        font: normal 800 20px "Honeywell-Medium";
        align-items: center;
        text-align: right;
        color: #279d2b;
        text-transform: uppercase;

        span {
          display: block;
          margin-bottom: 6px;

          &:last-child {
            font-size: 16px;
          }

          &.close-value {
            color: #303030;
          }

          &.close-value,
          &.open-value {
            span:last-child {
              font-size: 14px;
            }
          }

          strong {
            padding-right: 2px;
          }
        }
      }

      .btn-pay {
        padding: 0px 15px 0px 15px;

        .btn {
          font: normal 700 14px "Honeywell-light";
          margin: 0;
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .info-ftr {
    display: flex;
    margin-top: 18px;

    >div {
      width: 14%;
      margin-right: 14px;

      span {
        font: normal 700 15px "Honeywell-Medium";
        color: #303030;
        display: block;
        padding-bottom: 6px;

        &:last-child {
          color: #606060;
          font: normal 500 14px "Honeywell-Medium";
        }

        &.contract-no {
          color: #0071b3;
        }
      }

      &:first-child {
        width: 39% !important;
      }

      &:last-child,
      &:nth-child(4) {
        width: 13%;
      }
    }
  }

  .filter-sort {
    display: flex;
    padding-bottom: inherit;
    margin-top: 10px;

    .dropdown {
      width: auto;
    }

    .filter-container {
      padding-right: 35px;
    }
  }
}

.tail-row {
  height: 38px;
  background: #f3f3f3;
  display: flex;

  div {
    font: normal 700 14px "Honeywell-Medium";
    text-transform: uppercase;
    padding: 10px;
    color: #1d1d1d;

    &:first-child {
      width: 30%;
    }

    &:last-child {
      width: 68%;
    }
  }
}

.tail-num {
  display: flex;
  border-bottom: 1px solid #e9e9e9;

  >span {
    padding: 10px;
    color: #030303;

    &:first-child {
      width: 30%;
      font: normal 800 16px "Honeywell-Medium";

      span.info-icon {
        top: -3px;
      }
    }

    &:last-child {
      width: 68%;
      font: normal 500 16px "Honeywell-Medium";
    }
  }
}

.tail-cnt-row {
  min-height: 260px;
}

.tail-num .info-icon {
  .tool-tip {
    width: 216px;
    left: -79px;

    &::before,
    &::after {
      left: 74px;
    }
  }
}

.select-btns {
  text-align: right;
}

.btn {
  margin-right: 10px;
}

.load-more {
  text-align: center;
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 30px;
}

.disebled {
  pointer-events: none;
  background: #bdbfc2;
}

.paginate-cnt {
  margin: 12px 0px 10px;
  display: inline-flex;
  width: 100%;
}

a {
  text-decoration: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.top-btns {
  margin: 15px -20px 15px 0;
}

.view-state {
  position: absolute;
  right: 0;
  top: 36px;
}