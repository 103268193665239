.rates-container {
  width: 545px;
  height: 570px;
  position: relative;
  top: -15px;
  left: -20px;
}

.rates-header-list {
  width: 545px;
  height: 40px;
  .rates-header {
    width: 100%;
    height: 60%;
    font: normal 700 25px Honeywell-Medium !important;
    color: #303030;
  }
  .rates-date {
    width: 100%;
    height: 40%;
    font: normal 700 14px Honeywell-Medium;
    color: #606060;
    padding-left: 2px;
  }
}

.rates-details {
  width: 510px;
  height: 425px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  .engine-rates {
    width: 250px;
    height: 425px;
    .engine-header {
      width: 250px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      span {
        font: normal 700 18px "Honeywell-Medium";
        color: #000000;
      }
    }
    .rates-error {
      width: 250px;
      height: 150px;
      margin-top: 150px;
      p {
        font: normal 500 14px "Honeywell-Medium";
        color: red;
      }
    }
    .engine-details {
      width: 250px;
      height: 130px;
      border-bottom: 1px solid #c0c0c0;
      .rates-data {
        width: 250px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
          &:last-child {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }
    .discount-details {
      width: 250px;
      height: 150px;
      border-bottom: 1px solid #c0c0c0;
      .discount-header {
        width: 250px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
        }
      }
      .discount-data {
        width: 250px;
        height: 120px;
        .discount-rate {
          width: 250px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
            &:last-child {
              font: normal 500 16px "Honeywell-Medium";
              color: #303030;
            }
          }
        }
      }
    }
    .subtotal-details {
      width: 250px;
      height: 75px;
      border-bottom: 1px solid #c0c0c0;
      .subtotal-data {
        width: 250px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
          &:last-child {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
        &:last-child span {
          font: normal 500 16px "Honeywell-Medium";
          color: #303030;
          &:last-child {
            font: normal 500 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }
    .total-details {
      width: 250px;
      height: 45px;
      .total-data {
        width: 250px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          font: normal 700 16px "Honeywell-Medium";
          color: #303030;
          &:last-child {
            font: normal 700 16px "Honeywell-Medium";
            color: #303030;
          }
        }
      }
    }
  }
}
