.links_info {
  position: relative;
  min-height: 200px;
  background: #fff;
  margin-top: 20px;
  padding: 12px 20px 30px;
  font-family: "Honeywell-Bold";
  > a {
    text-decoration: none;
    font: normal 500 16px "Honeywell-Medium";
    color: #0071b3;
    padding-top: 8px;
    display: block;
    &:nth-child(3) {
      font-weight: 800;
    }
  }
  > p {
    text-decoration: none;
    font: normal 500 16px "Honeywell-Medium";
    color: #0071b3;
    cursor: pointer;
    padding-top: 8px;
    display: block;
    &:nth-child(3) {
      font-weight: 800;
    }
  }
}
