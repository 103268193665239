@import "../../../../variables.scss";
.prg-bar{
	margin-top: 50px;
	.respirator-steps {
		ul {
			display: flex;
			margin: auto;
			overflow: hidden;
			padding: 0;
			position: relative;
			text-align: center;
			z-index: 1;
		}
		li {
			list-style-type: none;
			color: $grey-dark1;
			line-height: normal;
			flex: 1;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
			text-align: center;
			text-transform: capitalize;
			position: relative;
			padding: 0 2%;
			&:before {
				content: counter(step);
				width: 38px;
				height: 38px;
				display: block;
				background: $white;
				border-radius: 50%;
				margin: 0 auto 10px;
				position: relative;
				z-index: 1;
				text-indent: -9999999px;
				border: 6px solid $grey13;
			}
			&:after {
				content: "";
				width: 100%;
				height: 8px;
				background: $grey13;
				position: absolute;
				left: -50%;
				top: 15px;
				z-index: -1;
			}
			&:first-child {
				&:after {
					content: none;
				}
			}
		}
		li.complete {
			&:before {
				background: url('../../../../assets/images/transferStatus.png');
				background-position: 50% 50%; 
				border: 6px solid $blue;
				color: $white;
			}
			&:after {
				background: $blue;
			}
		}
		li.completed {
			&:before {
				background: $green;
				background-position: 50% 50%; 
				border: 6px solid $green;
				color: $white;
			}
			&:after {
				background: $blue;
			}
		}
		
	}
	.steps-responsive {
		display: none;
	}
	@media (max-width: 480px) {
		.respirator-steps {
			display: none;
		}
		.steps-responsive {
			display: flex;
			.content {
				flex: 1 0 25%;
				font-weight: 400;
				font-size: 14px;
				line-height: 17px;
				color: $grey-dark1;
				text-align: left;
			}
			.bar {
				flex: 1 0 75%;
				padding-top: 6px;
			}
			.progress {
				height: 6px;
				background-color: $grey2;
				border-radius: 20px;
			}
			.progress-bar {
				background-color: $blue1;
			}
		}
	}
}
