@import "../../../../variables.scss";
.transfer-contract-modal {
  width: 1000px;
  height: 600px;
  position: relative;
  scroll-behavior: auto;
  right: 50%;

  .modal-body {
    overflow-y: scroll;
    height: 550px;
  }

  h1 {
    font: 700 28px "Honeywell-Medium";
  }

  .sub-heading {
    font: 700 20px "Honeywell-Medium";
    color: #000;
    margin-left: 31px;
  }
  .tail-selection {
    margin-left: 31px;
    .tail-header {
      span {
        font: 700 20px "Honeywell-Medium";
        color: #000;
      }
    }
    .tail-radio {
      padding: 15px 0 15px 0;
      align-items: flex-start;
      .tail-radio-btn {
        width: 10%;
        display: inline-table;
        margin: 0 20px 5px 0;
        input[type="radio"]#tail {
          margin-right: 10px;
          accent-color: #0071b3;
          width: 15px;
          height: 20px;
        }
        label {
          font: 700 14px "Honeywell-Medium";
          color: #303030;
          margin-top: 6px;
          margin-bottom: 0 !important;
          position: absolute;
        }
      }
    }
  }

  .form-control {
    font-size: 14px;
  }

  input:placeholder-shown {
    font-style: italic;
  }

  textarea:placeholder-shown {
    font-style: italic;
  }

  input {
    line-height: 24px;
    margin-top: 5px;
  }

  .tail-details {
    .row-2 {
      display: grid;
      margin: 20px;
      font: 700 14px "Honeywell-Medium";
      color: #303030;
      line-height: 24px;
      margin-left: 31px;
      margin-right: 31px;

      textarea {
        min-height: 80px;
        font-size: 14px;
        border: 1px solid silver;
        border-radius: 4px;
        padding: 10px;
        margin-top: 5px;
      }

      span {
        font: 500 12px "Honeywell-Medium";
        line-height: 20px;
        color: #606060;
      }

      span.hint {
        font: 500 12px "Honeywell-Medium";
        color: #404040;
        line-height: 20px;
        border-bottom: 1px solid #d0d0d0;
        padding-bottom: 20px;
        margin-top: 5px;
      }
    }

    .row {
      display: flex;
      margin: 20px;

      label {
        font: 700 14px "Honeywell-Medium";
        color: #a0a0a0;
        line-height: 24px;
      }

      .col-1 {
        position: relative;
        width: 50%;
        display: grid;

        label {
          font: 700 14px "Honeywell-Medium";
          color: #a0a0a0;
          line-height: 24px;
        }

        input {
          line-height: 24px;
          margin-top: 5px;
        }
      }

      .col-2 {
        position: relative;
        width: 50%;
        display: grid;
        label {
          font: 700 14px "Honeywell-Medium";
          color: #a0a0a0;
          line-height: 24px;
        }
      }
    }
  }
  .error-border {
    border: 2px solid #f15a4f;
  }
  .file-error{
    font: 500 12px "Honeywell-Medium";
    color: $red;
    margin-top: 2px;
  }
  .buyer-details {
    span.hint {
      font: 500 12px "Honeywell-Medium";
      color: #404040;
      line-height: 24px;
      margin-left: 31px;
    }

    span.optional {
      font: 500 12px "Honeywell-Medium";
      line-height: 20px;
      color: #606060;
    }

    .row {
      display: flex;
      margin: 20px;

      label {
        font: 700 14px "Honeywell-Medium";
        color: #303030;
        line-height: 24px;
      }

      .col-1 {
        position: relative;
        width: 50%;
        display: grid;
      }

      .col-2 {
        position: relative;
        width: 50%;
        display: grid;
      }
    }
  }

  .react-tel-input {
    font: 700 14px "Honeywell-Medium";
    font-size: 14px;
    position: relative;
    width: 70%;
    margin-top: 5px;

    .form-control {
      height: 38px;
    }
  }

  .extention {
    width: 20%;
    font: 700 14px "Honeywell-Medium";
    position: absolute;
    float: right;
    right: 12px;
  }

  .modal-footer {
    display: block;
    width: 100%;

    .submit-modal-container {
      border-top: 1px solid #c0c0c0;
      padding-top: 20px;
    }

    .accept-modal-form {
      float: left;
      width: 67%;
      margin-right: 15px;
    }

    input.checkbox {
      height: 25px;
      width: 20px;
      position: absolute;
      margin-left: 30px;
      accent-color: #0071b3;
    }

    h6 {
      font: 700 14px "Honeywell-Medium";
      color: #404040;
      margin-left: 60px;
    }
  }
}

.transfer-contract-display{
  display: none;
}
