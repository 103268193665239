@import "../../../../variables.scss";

.accept-modal {
  min-height: 100px;
  .accept-modal-body {
    text-align: left;
    padding-left: 10px;
  }
  .input-check {
    display: flex;
    input[type="checkbox"] {
      margin-right: 10px;
      &::before {
        top: 3px !important;
      }
      &::after {
        top: 0px !important;
      }
    }

    label {
      font: 500 16px "Honeywell-Medium";
      color: $gray-80;
    }
  }
}