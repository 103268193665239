.credit-inv{
    margin-bottom: 5px;
    margin-left: 5px;
  }
  .re-pdt-cnt {
      box-shadow: -2px 2px 5px 5px #8888884a;
      margin: 0px 12px 20px;
      overflow: auto;
      height: 360px;
    }
    .modal-header {
      padding-top: 35px !important;
      padding-bottom: 0 !important;
    }
    .react-pdf__Page__canvas {
      margin: 0 auto;
      width: 100% !important;
      height: 950px !important;
    }