.multipleInvoice-cnt {
  margin-bottom: 20px;
  .report-tbl-header + div {
    max-height: 270px;
    overflow: auto;
  }
  .report-tbl-header,
  .report-tbl-row {
    min-height: 36px;
    div {
      width: 18%;
      font-size: 14px;
      display: block;
      text-align: center;
      padding: 10px 5px;
      &:nth-child(5) {
        width: 10%;
      }
      &:last-child,
      &:nth-child(6) {
        width: 25%;
      }
    }
  }

  .invoice-total {
    width: 93%;
    overflow: hidden;
    padding-top: 14px;

    div {
      width: 21%;
      float: right;
      font: normal 700 14px "Honeywell-Medium";
      color: #1d1d1d;
      padding-left: 4%;
      text-align: center;

      &:last-child {
        width: 6%;
      }
    }
  }
  span.info-icon .tool-tip {
    width: 270px;
    left: -120px;
    &::before,
    &::after {
      left: 115px;
    }
  }
  .tbl-cnt .report-tbl-row{
    .input-check-box{
      input{
        width: 20px;
        height: 22px;
      }
    }
  }
}

.no-data-invoices {
  text-align: center;
  padding: 150px 10px;
}
p.red {
  color: #f00;
}
