@font-face {
    font-family: 'Honeywell-Light';
    src: url('./HoneywellSansWeb-Light.woff2') format('woff2'),
        url('./HoneywellSansWeb-Light.woff') format('woff');
 
}

@font-face {
    font-family: 'Honeywell-Book';
    src: url('./HoneywellSansWeb-Book.woff2') format('woff2'),
        url('./HoneywellSansWeb-Book.woff') format('woff');
  
}

@font-face {
    font-family: 'Honeywell-Bold';
    src: url('./HoneywellSansWeb-Bold.woff2') format('woff2'),
        url('./HoneywellSansWeb-Bold.woff') format('woff');
  
}

@font-face {
    font-family: 'Honeywell-Medium';
    src: url('./HoneywellSansWeb-Medium.woff2') format('woff2'),
        url('./HoneywellSansWeb-Medium.woff') format('woff');
  
}

