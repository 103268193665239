.upload-btn-text {
  font-size: 12px;
  width: 140px;
  border-radius: 4px;
  padding: 6px;
  font-family: "Honeywell-Bold";
}

.btn-blue:focus {
  outline: none;
  box-shadow: none !important;
}

.modal-upload-button-border {
  border-bottom: 0 !important;
  .btn-close {
    margin: 0;
    position: absolute;
    right: 12px;
    top: 12px;
  }
}

.modal-body {
  font-size: 10px;
  color: grey;
  margin-top: -10px;
  a {
    text-decoration: none;
  }
  > p {
    font: 16px "Honeywell-Book";
    color: #1d1d1d;
  }
}

.modal-header {
  font-family: "Honeywell-Bold";
}

.modal-upload-button-border {
  border-bottom: 0 !important;
}
.modal-cancel-text {
  color: #0071b3;
  font: 12px "Honeywell-Medium";
}
.modal-submit-text {
  font: 12px "Honeywell-Medium";
}
.upload-usage {
  position: relative;
  > input {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 13px 94px 13px 13px;
    font: normal 400 16px "Honeywell-Book";
    background-color: transparent;
    border: 1px solid #6d6d6d;
    border-radius: 4px;
    z-index: 9999;
    &:focus {
      background-color: transparent;
      border-color: #6d6d6d;
      box-shadow: none;
    }
  }
  > p {
    color: #1d1d1d;
    font: normal 400 12px "Honeywell-Medium";
    padding-top: 7px;
  }
}
.choose {
  position: absolute;
  font: normal 700 16px "Honeywell-Bold";
  color: #0071b3;
  cursor: pointer;
  right: 22px;
  top: 15px;
  z-index: 0;
  text-transform: uppercase;
}

.upload-success {
  text-align: center;
  padding-top: 20px;
  .modal-title {
    width: 100%;
  }

  & + .modal-body {
    .upload-error-info {
      color: #f00;
      font: normal 500 14px "Honeywell-Book";
      text-align: center;
    }
    > p {
      text-align: center;
    }
    & + .modal-footer {
      justify-content: center;
      padding-bottom: 20px;
    }
  }
}
