.rc-results-wrapper11 {
 
  .pdf-body {
    padding: 0 10px;
    color: #000;
    margin: 0 !important;
    .subject {
      font: normal 800 14px "Honeywell-Medium";
      color: #000;
    }
    .info-txt1{
      margin-left: 10px;
      font-size: 11px;
    }
    p{
      margin-bottom: 2px !important;
    }
  }
  .pdf-header {
    display: flex;
    width: 100%;
    color: #000;
    .hw-pdf-logo {
      width: 200px !important;
      padding: 20px 0 0 20px;
      div {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
    }
    .customer-info {
      width: 33%;
      padding: 0 1%;
      margin-left: auto;
      margin-top: 40px;
      .customer-name {
        font: normal 800 13px "Honeywell-Medium";
        color: #313131;
      }
      div {
        font: normal 600 10px "Honeywell-Medium";
        color: #1d1d1d;
      }
      .email {
        white-space: nowrap;
      }
    }
  }
}
